/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Service, { inject as service } from '@ember/service';
import type { Block } from '@intercom/interblocks.ts';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import { type BlockGroup } from 'embercom/components/knowledge-hub/content-editor/shared/suggestion-editor';
import type Model from '@ember-data/model';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type ArticleContent from 'embercom/models/articles/article-content';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { EntityType } from 'embercom/models/data/entity-types';
import { action } from '@ember/object';
import { BlockEditType, ReviewType } from 'embercom/models/content-service/content-review-request';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { get } from 'embercom/lib/ajax';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';

export type TopicSummary = {
  topic: string;
  total_count: number;
  subtopics: {
    subtopic: string;
    count: number;
  }[];
};

type TopicDetails = {
  totalCount: number;
  subtopics: {
    name: string;
    count: number;
  }[];
};
export default class SuggestionService extends Service {
  @service declare appService: $TSFixMe;
  @tracked declare activeContent: LocalizedKnowledgeContent & Model;
  @tracked blockGroups: BlockGroup[] = [];
  @tracked blockGroupsForEditing: { [key: string]: Block[] } = {};
  @tracked topicsSummary: Map<string, TopicDetails> = new Map();

  getSuggestionCountForTopics(topics: string[]): number {
    if (isEmpty(topics)) {
      return 0;
    }

    let sum = 0;
    for (let topic of topics) {
      let topicDetails = this.topicsSummary.get(topic);
      if (topicDetails) {
        sum += topicDetails.totalCount;
      }
    }
    return sum;
  }

  private getJsonBlocks(activeContent: LocalizedKnowledgeContent & Model): Block[] {
    return activeContent.entityType === EntityType.ContentSnippet
      ? (activeContent as ContentSnippet).serializedBlocks
      : (activeContent as unknown as ArticleContent).jsonBlocks;
  }

  async fetchTopicsSummary(useTimeout = false): Promise<void> {
    if (useTimeout) {
      await timeout(ENV.APP._1000MS);
    }
    let topicsSummary = await get('/ember/ai_insights/topics_summary', {
      app_id: this.appService.app.id,
    });

    // convert to map for faster lookup
    this.topicsSummary = topicsSummary.reduce(
      (map: Map<string, TopicDetails>, { topic, total_count, subtopics }: TopicSummary) => {
        map.set(topic, {
          totalCount: total_count,
          subtopics: subtopics.map(({ subtopic, count }) => ({
            name: subtopic,
            count,
          })),
        });
        return map;
      },
      new Map(),
    );
  }

  get editedJsonBlocks() {
    return this.blockGroups.flatMap((blockGroup) => {
      if (
        blockGroup.reviewRequest?.get('status') === Status.Rejected ||
        blockGroup.reviewRequest?.get('status') === Status.Pending
      ) {
        if (blockGroup.reviewRequest?.get('reviewType') === ReviewType.CREATE_CONTENT) {
          return blockGroup.blocks;
        }
        return blockGroup.blocksBefore ?? [];
      }

      return blockGroup.blocks;
    });
  }

  @action
  createBlockGroups(
    activeContent: LocalizedKnowledgeContent & Model,
    reviewRequests: ContentReviewRequest[],
  ): BlockGroup[] {
    let editedBlockCounter = 0;
    let uneditedBlockCounter = 0;
    let blockGroups: BlockGroup[] = [];
    let currentIndex = 0;
    let jsonBlocks = this.getJsonBlocks(activeContent);

    this.blockGroupsForEditing = {};

    let sortedRequests = this.getSortedReviewRequests(reviewRequests);

    for (let request of sortedRequests) {
      if (request.get('reviewType') === ReviewType.CREATE_CONTENT) {
        this.handleCreateContentRequest(blockGroups, request, jsonBlocks);
        // Set currentIndex to the end of the jsonBlocks array because we only have one suggestion for create content
        currentIndex = jsonBlocks.length + 1;
      } else {
        let result = this.handleEditContentRequest(
          blockGroups,
          request,
          jsonBlocks,
          currentIndex,
          uneditedBlockCounter,
          editedBlockCounter,
        );

        currentIndex = result.currentIndex;
        uneditedBlockCounter = result.uneditedBlockCounter;
        editedBlockCounter = result.editedBlockCounter;
      }
    }

    // Add any remaining unedited blocks after the last suggestion
    this.handleRemainingUneditedBlocks(blockGroups, jsonBlocks, currentIndex, uneditedBlockCounter);

    this.blockGroups = blockGroups;
    return blockGroups;
  }

  private getSortedReviewRequests(reviewRequests: ContentReviewRequest[]): ContentReviewRequest[] {
    let validRequests = reviewRequests.filter((request) => !isEmpty(request.get('suggestion')));

    // Sort requests by suggestion start index
    if (validRequests.length > 0) {
      return [...validRequests].sort(
        (a, b) => a.get('suggestion')!.indices[0] - b.get('suggestion')!.indices[0],
      );
    }
    return validRequests;
  }

  private handleCreateContentRequest(
    blockGroups: BlockGroup[],
    request: ContentReviewRequest,
    jsonBlocks: Block[],
  ) {
    blockGroups.push({
      id: 'unedited-block-group-0',
      isEdited: false,
      blocks: jsonBlocks,
      reviewRequest: request,
    });
  }

  private handleEditContentRequest(
    blockGroups: BlockGroup[],
    request: ContentReviewRequest,
    jsonBlocks: Block[],
    currentIndex: number,
    uneditedBlockCounter: number,
    editedBlockCounter: number,
  ) {
    let suggestion = request.get('suggestion')!;
    let [startIndex, endIndex] = suggestion.indices;

    // Add unedited blocks before the suggestion if any
    if (currentIndex < startIndex) {
      let blocksForGroup = jsonBlocks.slice(currentIndex, startIndex);

      blockGroups.push({
        id: `unedited-block-group-${uneditedBlockCounter++}`,
        isEdited: false,
        blocks: blocksForGroup,
        blocksBefore: blocksForGroup,
      });
    }

    let reviewedBlocks = jsonBlocks.slice(startIndex, endIndex + 1);

    if (suggestion.blockEditType === BlockEditType.APPEND_NEW_BLOCKS) {
      // First add the referenced blocks as unedited
      blockGroups.push({
        isEdited: false,
        id: `unedited-block-group-${uneditedBlockCounter++}`,
        blocks: reviewedBlocks,
        blocksBefore: reviewedBlocks,
      });

      // Then add the new blocks as a new group after the end index
      blockGroups.push({
        isEdited: true,
        id: `edited-block-group-${editedBlockCounter++}`,
        blocks: suggestion.editedBlocks,
        blocksBefore: [], // Empty blocksBefore since this is an append
        reviewRequest: request,
      });
    } else {
      blockGroups.push({
        isEdited: true,
        id: `edited-block-group-${editedBlockCounter++}`,
        blocks: suggestion.editedBlocks,
        blocksBefore: reviewedBlocks,
        reviewRequest: request,
      });
    }

    currentIndex = endIndex + 1;
    return { currentIndex, uneditedBlockCounter, editedBlockCounter };
  }

  private handleRemainingUneditedBlocks(
    blockGroups: BlockGroup[],
    jsonBlocks: Block[],
    currentIndex: number,
    uneditedBlockCounter: number,
  ) {
    let blocksAfterSuggestions = jsonBlocks.slice(currentIndex);

    if (currentIndex < jsonBlocks.length) {
      blockGroups.push({
        isEdited: false,
        id: `unedited-block-group-${uneditedBlockCounter++}`,
        blocks: blocksAfterSuggestions,
        blocksBefore: blocksAfterSuggestions,
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    SuggestionService: SuggestionService;
    'suggestion-service': SuggestionService;
  }
}
