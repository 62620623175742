/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { action } from '@ember/object';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import Signal from 'embercom/models/reporting/signal';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

export const REPORTING_RANGE_UPDATED = 'range_updated';
export const CONTENT_TYPE_IDS_UPDATED = 'content_type_ids_updated';

export default class ReportingService extends Service.extend(Evented) {
  @service router;
  @service appService;
  @service notificationsService;

  @tracked range = null;

  get app() {
    return this.appService.app;
  }

  fetchSignal(signalConfig, valueUnit, range, filters, aggregations, name) {
    filters = filters || {};
    let { start, end, interval, baseAggregations } = range;

    return this._fetchSignal(
      signalConfig,
      valueUnit,
      start,
      end,
      aggregations || baseAggregations,
      filters,
      name,
      interval,
    );
  }

  @action
  updateRange(range, excludeBotInboxStartDate = null, triggerEvent = true, minStartDate = null) {
    if (range.comparisonStartIsMoreThanTwoYearsAgo) {
      this.notificationsService.notifyWarning(
        'We only store data for 2 years. You won’t see ‘previous period’ data before that date.',
        5000,
      );
    } else if (excludeBotInboxStartDate && range.startMoment.isBefore(excludeBotInboxStartDate)) {
      this.notificationsService.notifyWarning(
        `Data to exclude bots is available from ${moment(excludeBotInboxStartDate).format(
          dateAndTimeFormats.date,
        )}, when your bot inbox was activated.`,
        5000,
      );
    } else if (minStartDate && range.startMoment.isBefore(minStartDate)) {
      this.notificationsService.notifyWarning(
        `Data for this report is available from ${moment(minStartDate).format(
          dateAndTimeFormats.date,
        )}. You won’t see data before that date.`,
        5000,
      );
    } else if (minStartDate && range.comparisonStartMoment.isBefore(minStartDate)) {
      this.notificationsService.notifyWarning(
        `Data for this report is available from ${moment(minStartDate).format(
          dateAndTimeFormats.date,
        )}. You won’t see ‘previous period’ data before that date.`,
        5000,
      );
    }

    this.set('range', range);
    if (triggerEvent) {
      this.trigger(REPORTING_RANGE_UPDATED);
    }
  }

  updateContentTypeIds() {
    this.trigger(CONTENT_TYPE_IDS_UPDATED);
  }

  fetchHeatmapSignal(signalConfig, valueUnit, range, filters) {
    let { start, end } = range;
    return this._fetchSignal(
      signalConfig,
      valueUnit,
      start,
      end,
      range.heatmapAggregations,
      filters,
    ).then((signal) => {
      signal.set('keysAreDates', false);
      return signal;
    });
  }

  fetchComparisonNumericSignal(signalConfig, valueUnit, range, aggregations, filters = {}) {
    return hash({
      current: this.fetchNumericSignal(signalConfig, valueUnit, range, aggregations, filters),
      previous: this.fetchPreviousNumericSignal(
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
      ),
    }).then((signals) => {
      let aggregateSignal = signals.current;
      aggregateSignal.setComparison(signals.previous);
      return aggregateSignal;
    });
  }

  fetchNumericSignal(signalConfig, valueUnit, range, aggregations, filters) {
    let { start, end } = range;
    return this._fetchNumericSignal(signalConfig, valueUnit, start, end, aggregations, filters);
  }

  fetchPreviousNumericSignal(signalConfig, valueUnit, range, aggregations, filters) {
    let { comparisonStart, comparisonEnd } = range;
    return this._fetchNumericSignal(
      signalConfig,
      valueUnit,
      comparisonStart,
      comparisonEnd,
      aggregations,
      filters,
    );
  }

  _fetchNumericSignal(signalConfig, valueUnit, rangeStart, rangeEnd, aggregations, filters) {
    return this._fetchSignal(
      signalConfig,
      valueUnit,
      rangeStart,
      rangeEnd,
      aggregations,
      filters,
    ).then((signal) => {
      signal.setProperties({
        keysAreDates: false,
        nestedKeysAreDates: false,
      });
      return signal;
    });
  }

  fetchStackedSignal(
    { signalConfig, valueUnit, range, aggregations, filters, debugName },
    transform = (signal) => signal,
  ) {
    return hash({
      current: this._fetchCurrentSignal(
        { ...signalConfig, debug_name: debugName },
        valueUnit,
        range,
        aggregations,
        filters,
      ).then(transform),
      previous: this._fetchPreviousSignal(
        { ...signalConfig, debug_name: debugName },
        valueUnit,
        range,
        aggregations,
        filters,
      ).then(transform),
    }).then((signals) => {
      let aggregateSignal = signals.current;
      aggregateSignal.setComparison(signals.previous);
      return aggregateSignal;
    });
  }

  fetchAnswerBotEffectivenessSignal(signalConfig, valueUnit, range, filters) {
    let { answerEffectivenessAggregation } = range;
    let aggregations = [answerEffectivenessAggregation];

    let transformSignalToConversationRatingSignal = (signal) => {
      signal.setProperties({
        keysAreDates: false,
        nestedKeysAreDates: false,
      });
      return signal;
    };

    return this.fetchStackedSignal(
      {
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
      },
      transformSignalToConversationRatingSignal,
    );
  }

  fetchFinInvolvementSignal(signalConfig, valueUnit, range, filters) {
    let finParticipatedAggregation = { grouping: 'fin.participated', interval: 1 };
    let aggregations = [finParticipatedAggregation];

    let transformSignalToFinInvolvementSignal = (signal) => {
      signal.setProperties({
        keysAreDates: false,
        nestedKeysAreDates: false,
      });
      return signal;
    };

    return this.fetchStackedSignal(
      {
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
      },
      transformSignalToFinInvolvementSignal,
    );
  }

  fetchFinResolvedConversationsSignal(signalConfig, valueUnit, range, filters) {
    let finParticipatedAggregation = { grouping: 'fin.resolution_state', interval: 1 };
    let aggregations = [finParticipatedAggregation];

    let transformSignalToFinResolutionsSignal = (signal) => {
      signal.setProperties({
        keysAreDates: false,
        nestedKeysAreDates: false,
      });
      return signal;
    };

    return this.fetchStackedSignal(
      {
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
      },
      transformSignalToFinResolutionsSignal,
    );
  }

  fetchConversationRatingSignal(signalConfig, valueUnit, range, filters) {
    let { baseAggregations, conversationRatingsAggregation } = range;
    let aggregations = baseAggregations.concat(conversationRatingsAggregation);

    let transformSignalToConversationRatingSignal = (signal) => {
      signal.setProperties({
        keysAreDates: true,
        nestedKeysAreDates: false,
      });
      return signal;
    };

    return this.fetchStackedSignal(
      {
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
      },
      transformSignalToConversationRatingSignal,
    );
  }

  async fetchComparisonTagSignal(signalConfig, valueUnit, range, filters = {}) {
    let tagAggregations = range.tagAggregations;
    let signals = await this._fetchComparisonTagSignalHash(
      signalConfig,
      valueUnit,
      range,
      tagAggregations,
      filters,
    );
    let aggregateSignal = await this._processTagSignal(signals.current);
    aggregateSignal.setComparison(this._processTagSignal(signals.previous));
    return aggregateSignal;
  }

  fetchComparisonSignal(signalConfig, valueUnit, range, filters, aggregations, name) {
    filters = filters || {};
    aggregations = aggregations || range.baseAggregations;

    return hash({
      current: this._fetchCurrentSignal(
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
        name,
      ),
      previous: this._fetchPreviousSignal(
        signalConfig,
        valueUnit,
        range,
        aggregations,
        filters,
        name,
      ),
    }).then((signals) => {
      let aggregateSignal = signals.current;
      aggregateSignal.setComparison(signals.previous);
      return aggregateSignal;
    });
  }

  fetchTabularSignal(signalConfig, range, filters) {
    filters = filters || {};
    let { start, end, comparisonStart, comparisonEnd } = range;

    return hash({
      current: this._fetchTabularSignal(signalConfig, start, end, filters),
      previous: this._fetchTabularSignal(signalConfig, comparisonStart, comparisonEnd, filters),
    });
  }

  _fetchCurrentSignal(signalConfig, valueUnit, range, aggregations, filters) {
    let { start, end, interval } = range;
    return this._fetchSignal(
      signalConfig,
      valueUnit,
      start,
      end,
      aggregations,
      filters,
      name,
      interval,
    );
  }

  _fetchPreviousSignal(signalConfig, valueUnit, range, aggregations, filters) {
    let { comparisonStart, comparisonEnd, interval } = range;
    return this._fetchSignal(
      signalConfig,
      valueUnit,
      comparisonStart,
      comparisonEnd,
      aggregations,
      filters,
      name,
      interval,
    );
  }

  _fetchComparisonTagSignalHash(signalConfig, valueUnit, range, tagAggregations, filters) {
    return hash({
      current: this._fetchCurrentSignal(signalConfig, valueUnit, range, tagAggregations, filters),
      previous: this._fetchPreviousSignal(signalConfig, valueUnit, range, tagAggregations, filters),
    });
  }

  _processTagSignal(signal) {
    let contextWithoutDeletedTags = signal
      .get('context')
      .map((tagContext) => {
        let tag = this._getTagById(tagContext.key);
        if (!tag) {
          return null;
        }

        return { ...tagContext, icon: 'tag', label: tag.get('name') };
      })
      .compact();
    return Signal.create({ ...signal, context: contextWithoutDeletedTags });
  }

  _getTagById(tagId) {
    let tags = this.get('app.tags');
    return tags.findBy('id', `${tagId}`);
  }

  _fetchTabularSignal(signalConfig, rangeStart, rangeEnd, filters = {}) {
    return Signal.fetchTabularSignal(this.app, signalConfig, rangeStart, rangeEnd, filters);
  }

  _fetchSignal(
    signalConfig,
    valueUnit,
    rangeStart,
    rangeEnd,
    aggregations,
    filters = {},
    name,
    interval,
  ) {
    return Signal.fetchSignal(
      this.app,
      signalConfig,
      valueUnit,
      rangeStart,
      rangeEnd,
      aggregations,
      filters,
      name,
      interval,
    );
  }
}
