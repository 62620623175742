/* RESPONSIBLE TEAM: team-knowledge-foundations */
import type Store from '@ember-data/store';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import { post } from 'embercom/lib/ajax';
import type ArticleContent from 'embercom/models/articles/article-content';
import type InternalArticle from 'embercom/models/content-service/internal-article';
import { EntityType } from 'embercom/models/data/entity-types';
import { type PaywallConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import { tracked } from 'tracked-built-ins';
import { MAX_ARTICLES_IN_A_COLLECTION } from 'embercom/lib/articles/constants';
import type FileSourceContent from 'embercom/models/file-source-content';
import { capitalize } from '@ember/string';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import type KnowledgeHubDrawerEditorService from './knowledge-hub-drawer-editor-service';
import { action } from '@ember/object';
import { parseContentIdentifier, type ActiveContent } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export type OnContentUpdateCallbackFunction = (
  update: OnUpdateCallbackFunctionArgs | OnDeleteCallbackFunctionArgs,
) => void;
type OnUpdateCallbackFunctionArgs = {
  type: 'add' | 'edit' | 'move-folder';
  content: LocalizedKnowledgeContent & Model;
};
type OnDeleteCallbackFunctionArgs = { type: 'delete'; entityId: string; entityType: EntityType };

export type OnContentUpdateCallbackFunctionArgs = Parameters<OnContentUpdateCallbackFunction>[0];

export default class KnowledgeHubEditorService extends Service {
  @service declare router: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare store: Store;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare helpCenterService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked private activeContent?: LocalizedKnowledgeContent;
  @tracked onContentUpdateCallback?: OnContentUpdateCallbackFunction;
  @tracked isPublishing = false;
  @tracked showHistoryOverlay = false;
  @tracked showSetHelpCenterLiveModal = false;

  @action closeSetHelpCenterLiveModal(): void {
    this.showSetHelpCenterLiveModal = false;
  }

  @action openSetHelpCenterLiveModal(): void {
    this.showSetHelpCenterLiveModal = true;
  }

  get activeContentModel() {
    return this.activeContent as LocalizedKnowledgeContent & Model;
  }

  get contentId() {
    return this.activeContentModel?.id;
  }

  get activeContentIsInternalArticle() {
    return this.activeContent?.entityType === EntityType.InternalArticle;
  }

  get activeContentIsFileSourceContent() {
    return this.activeContent?.entityType === EntityType.FileSourceContent;
  }

  get activeContentIsContentSnippet() {
    return this.activeContent?.entityType === EntityType.ContentSnippet;
  }

  get activeContentIsPublicArticle() {
    return this.activeContent?.entityType === EntityType.ArticleContent;
  }

  get activeContentHasNoParent() {
    return !!this.activeContent?.parentContent.isRoot;
  }

  get app() {
    return this.appService.app;
  }

  registerActiveContent(activeContent: LocalizedKnowledgeContent | undefined) {
    this.activeContent = activeContent;
  }

  @action
  registerOnContentUpdateCallback(onContentUpdateFunction: OnContentUpdateCallbackFunction) {
    this.onContentUpdateCallback = onContentUpdateFunction;
  }

  @action
  async editActiveContent() {
    this.trackAnalyticsEvent('clicked_edit');
    if (!this.activeContent) {
      return;
    }
    // first refetch the content to make sure we have the latest version
    await this.knowledgeHubService.findContent({
      contentId: Number(this.contentId),
      contentTypeName: this.activeContent?.entityName,
      findRecordParams: { reload: true },
    });
    this.knowledgeHubDrawerEditorService.openEditDrawer({
      activeContentId: this.contentId,
      activeContentType: this.activeContent?.entityName,
    });
  }

  cancelEditActiveContent({ redirectToView = true }: { redirectToView?: boolean } = {}) {
    if (this.activeContent) {
      if (this.activeContentIsInternalArticle) {
        let internalArticle = this.activeContentModel as InternalArticle;
        internalArticle.currentVersion?.rollbackAttributes();
      }

      this.activeContentModel.rollbackAttributes();

      this.trackAnalyticsEvent('cancelled_edit');

      if (!redirectToView) {
        return;
      }

      if (!this.activeContent) {
        this.notificationsService.notifyError(
          this.intl.t('knowledge-hub.content-editor.edit.no-active-content'),
        );
        return;
      }
      this.knowledgeHubDrawerEditorService.openViewDrawer({
        activeContentId: this.contentId,
        activeContentType: this.activeContent?.entityName,
      });
    }
  }

  async deleteActiveContent() {
    try {
      let [entityType, entityId] = parseContentIdentifier(this.activeContentModel);
      let otherAvailableTranslations = this.routeToOtherTranslation;

      // TODO Rewrite this to be generic rather than specific to file source content
      if (this.activeContentIsFileSourceContent) {
        let fileSourceContent = this.activeContent as unknown as FileSourceContent;
        await fileSourceContent.delete();
      } else {
        await this.activeContentModel.destroyRecord();
      }
      this.notificationsService.notifyConfirmation(
        capitalize(
          this.intl.t('knowledge-hub.content-editor.delete.success-message', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        ),
      );
      this.trackAnalyticsEvent('deleted');

      if (otherAvailableTranslations) {
        let { model } = otherAvailableTranslations;
        let [entityName, otherTranslation] = model;

        if (this.activeContent) {
          this.knowledgeHubDrawerEditorService.openViewDrawer({
            activeContentId: otherTranslation.id,
            activeContentType: entityName,
          });
        }

        // Ideally, we would pass the parent as a content here because its the parent that we usually want to update
        // but we can't do that because ArticleContent's parent() is not implemented yet.
        // https://github.com/intercom/embercom/blob/d1adf6ae2b0fb2fdf1ed1fd6fb82f10b239d1a79/app/models/articles/article-content.ts#L588-L591
        //
        // Instead, we pass the sibling content as a workaround, as we can assume that the caller would
        // already have the logic to update the content's parent.
        this.onContentUpdateCallback?.({
          type: 'edit',
          content: otherTranslation as LocalizedKnowledgeContent & Model,
        });
        return;
      }
      if (!this.knowledgeHubDrawerEditorService.isNewContent) {
        await this.knowledgeHubDrawerEditorService.closeDrawer();
      }

      this.onContentUpdateCallback?.({
        type: 'delete',
        entityId,
        entityType,
      });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.delete.error-message'),
      );
    }
  }

  get routeToOtherTranslation() {
    if (this.activeContent?.entityType === EntityType.ArticleContent) {
      // TODO Re-write this to be generic rather than specific to articles
      let article = (this.activeContentModel as unknown as ArticleContent).article;
      let otherAvailableTranslations = article.orderedArticleContents.filter(
        (articleContent) => articleContent && articleContent.id !== this.activeContentModel.id,
      );

      // Redirect to the first available translation if the current content is an article
      if (otherAvailableTranslations.length !== 0) {
        return {
          route: 'apps.app.knowledge-hub.view',
          model: ['article', otherAvailableTranslations.firstObject!] as [string, ActiveContent],
        };
      }
    }
    return null;
  }

  async saveActiveContent({ redirectToView = true }: { redirectToView?: boolean } = {}) {
    if (this.activeContent) {
      try {
        if (this.activeContentIsInternalArticle) {
          let internalArticle = this.activeContentModel as InternalArticle;
          if (internalArticle.currentVersion) {
            internalArticle.currentVersion.authorId = this.app.currentAdmin.id;
          }
        }
        await this.activeContentModel.save();
        // rollback attribiutes to avoid hasUnsavedChanges being true after save
        this.activeContentModel.rollbackAttributes();
        this.trackAnalyticsEvent('edited');
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.save-success-confirmation'),
        );

        if (!redirectToView) {
          this.onContentUpdateCallback?.({ type: 'edit', content: this.activeContentModel });
          return;
        }

        if (!this.activeContent) {
          return;
        }
        this.knowledgeHubDrawerEditorService.openViewDrawer({
          activeContentId: this.contentId,
          activeContentType: this.activeContent.entityName,
        });

        this.onContentUpdateCallback?.({ type: 'edit', content: this.activeContentModel });
      } catch (e) {
        if (e.jqXHR?.status === 400 && e.jqXHR?.responseJSON?.message) {
          this.notificationsService.notifyError(e.jqXHR.responseJSON.message);
        } else {
          this.notificationsService.notifyResponseError(e, {
            default: this.intl.t('knowledge-hub.content-editor.edit.save-error-message'),
          });
        }
      }
    }
  }

  async saveAndEditActiveContent() {
    if (this.activeContent) {
      await this.activeContentModel.save();
      this.knowledgeHubDrawerEditorService.openEditDrawer({
        activeContentId: this.contentId,
        activeContentType: this.activeContent?.entityName,
      });
    }
  }

  async updateHelpCenterAndCollection(article: any, sendCallback = true) {
    try {
      await article.get('updateSettingsMultiple').perform();
      await article.reload();
      if (sendCallback) {
        this.onContentUpdateCallback?.({ type: 'edit', content: this.activeContentModel });
      }
    } catch (error) {
      if (error.jqXHR?.responseJSON?.errors?.key === 'max_collection_articles_reached') {
        this.notificationsService.notifyError(
          this.intl.t('articles.collections.add_article.max_articles_reached', {
            limit: MAX_ARTICLES_IN_A_COLLECTION,
          }),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('articles.editor.side-drawer.settings.problem-in-saving-settings'),
        );
      }
    }
  }

  async publishActiveContent() {
    if (this.activeContent) {
      let entityType = this.activeContent.entityType;
      let hcIds: string[] = (this.activeContent as any).article.helpCenterIds;
      let liveHcIds = this.helpCenterService.allLiveSites.toArray().map((site: any) => site.id);
      let allAssociatedHelpCentersAreLive = hcIds.every((hcId: string) => liveHcIds.includes(hcId));
      let isUnlistedArticle = entityType === EntityType.ArticleContent && hcIds.length === 0;
      let isDefaultHelpCenterLive = this.helpCenterService.defaultSite.websiteTurnedOn;
      this.isPublishing = true;
      try {
        await this.activeContentModel.publishContent();
        this.trackAnalyticsEvent('published');
        this.activeContentModel.reload();
        // rollback attribiutes to avoid hasUnsavedChanges being true after publish
        this.activeContentModel.rollbackAttributes();

        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.publish-message.success-new', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        );
        if (!this.activeContent) {
          return;
        }
        this.knowledgeHubDrawerEditorService.openViewDrawer({
          activeContentId: this.contentId,
          activeContentType: this.activeContent.entityName,
        });
        this.onContentUpdateCallback?.({ type: 'edit', content: this.activeContentModel });

        if (
          entityType === EntityType.ArticleContent &&
          (!allAssociatedHelpCentersAreLive || (isUnlistedArticle && !isDefaultHelpCenterLive))
        ) {
          this.openSetHelpCenterLiveModal();
        }
      } catch (e) {
        if (e.jqXHR?.responseJSON?.message) {
          this.notificationsService.notifyError(e.jqXHR.responseJSON.message);
        } else {
          this.notificationsService.notifyResponseError(e, {
            default: this.intl.t('knowledge-hub.content-editor.edit.publish-message.error', {
              contentType: this.activeContent?.humanReadableContentName,
            }),
          });
        }
      } finally {
        this.isPublishing = false;
      }
    }
  }

  async unpublishActiveContent() {
    if (this.activeContent) {
      try {
        await this.activeContentModel.unpublishContent();
        this.trackAnalyticsEvent('unpublished');
        this.activeContentModel.reload();
        // rollback attribiutes to avoid hasUnsavedChanges being true after unpublish
        this.activeContentModel.rollbackAttributes();

        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.content-editor.edit.unpublish-message.success', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        );
        if (!this.activeContent) {
          return;
        }
        this.knowledgeHubDrawerEditorService.openViewDrawer({
          activeContentId: this.contentId,
          activeContentType: this.activeContent.entityName,
        });
        this.onContentUpdateCallback?.({ type: 'edit', content: this.activeContentModel });
      } catch (e) {
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t('knowledge-hub.content-editor.edit.unpublish-message.error', {
            contentType: this.activeContent?.humanReadableContentName,
          }),
        });
      }
    }
  }

  trackAnalyticsEvent(action: string, place = 'knowledge_hub_content_editor') {
    if (this.activeContent) {
      this.intercomEventService.trackAnalyticsEvent({
        action,
        object: this.activeContent.entityName,
        place,
        contentId: this.activeContentModel.id,
      });
    }
  }

  @restartableTask
  *changeInternalArticleVisibility(): TaskGenerator<void> {
    let internalArticle = this.activeContentModel as InternalArticle;
    try {
      let urlSuffix = internalArticle.isLive ? 'unpublish' : 'publish';
      let response = yield post(
        `/ember/content_service/internal_articles/${internalArticle.id}/${urlSuffix}`,
        {
          app_id: this.appService.app.id,
          id: internalArticle.id,
          version_id: internalArticle.currentVersion!.id,
        },
      );

      let successMessage = internalArticle.isLive
        ? this.intl.t('knowledge-hub.content-editor.internal-note.visibility-section.unpublished')
        : this.intl.t('knowledge-hub.content-editor.internal-note.visibility-section.published');

      this.notificationsService.notifyConfirmation(successMessage);
      this.trackAnalyticsEvent(`${urlSuffix}_internal_article`);
      this.store.pushPayload({ 'content-service/internal-article': response });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.content-editor.internal-note.visibility-section.error'),
      );
    }
  }

  async autoSaveContent() {
    await this.activeContentModel.save();
    this.activeContentModel.rollbackAttributes();
    this.onContentUpdateCallback?.({ type: 'edit', content: this.activeContentModel });
  }

  get paywallConfig() {
    let configs = this.activeContentModel?.editorConfig?.paywallConfig;
    return configs ? this.getPaywallConfigToShow(configs) : undefined;
  }

  private getPaywallConfigToShow(paywalls: PaywallConfig[], index = 0): PaywallConfig | undefined {
    if (index >= paywalls.length) {
      return undefined;
    }
    if (!this.appService.app.canUseFeature(paywalls[index].featureKey)) {
      return paywalls[index];
    }
    return this.getPaywallConfigToShow(paywalls, index + 1);
  }
}

declare module '@ember/service' {
  interface Registry {
    knowledgeHubEditorService: KnowledgeHubEditorService;
    'knowledge-hub-editor-service': KnowledgeHubEditorService;
  }
}
