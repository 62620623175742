/* RESPONSIBLE TEAM: team-ai-agent */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type DkimSettings from 'embercom/models/dkim-settings';
import type DetectIntercomradesDomainService from 'embercom/services/detect-intercomrades-domain-service';
import { get } from 'embercom/lib/ajax';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { FIN_SETUP_BEHAVIOR_ENDPOINT } from 'embercom/components/operator/fin/setup/settings';
import { EntityType } from 'embercom/models/data/entity-types';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type KnowledgeHubService from './knowledge-hub-service';

type ValidationResult = 'no_custom_sender' | 'default_reply_invalid';
type ValidationResponse = { isValid: boolean; type?: ValidationResult; message?: string };

interface FinStats {
  finUsableArticlesCount: number;
  finUsableExternalContentCount: number;
  finUsableSnippetsCount: number;
  finUsableActionsCount: number;
  finUsableFilesCount: number;
  liveFinProfilesCount: number;
  workflowsCount: WorkflowStats;
}

interface WorkflowStats {
  liveFinChatWorkflowsCount: number;
  liveFinEmailWorkflowsCount: number;
  liveFinPhoneWorkflowsCount: number;
  liveFinWorkflowsCount: number;
  hasFinChatWorkflows: boolean;
  hasFinEmailWorkflows: boolean;
  hasFinPhoneWorkflows: boolean;
}

interface SetupRuleset {
  emailSetupRuleset: $TSFixMe;
  finSetupRuleset: $TSFixMe;
  phoneSetupRuleset: $TSFixMe;
}

export default class AiAgentSetupService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare detectIntercomradesDomainService: DetectIntercomradesDomainService;
  @service declare intl: IntlService;
  @service declare contentImportService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked finStats: FinStats;
  @tracked setupRuleset: SetupRuleset;
  @tracked liveResolutionBotBehaviors: $TSFixMe;
  @tracked customAnswerCounts: {
    all: number;
    finUsable: number;
  };
  defaultReplyInvalidMessage: string;
  isLoading = false;
  lastFetchTime = 0;
  fetchInterval = 10000; // 10 seconds
  loadFinPromise: Promise<any> | null = null;

  constructor() {
    super(...arguments);
    this.defaultReplyInvalidMessage = this.intl.t(
      'operator.workflows.visual-builder.validations.fin.email.default-reply-invalid',
    );
    this.finStats = {
      finUsableArticlesCount: 0,
      finUsableExternalContentCount: 0,
      finUsableSnippetsCount: 0,
      finUsableActionsCount: 0,
      finUsableFilesCount: 0,
      liveFinProfilesCount: 0,
      workflowsCount: {
        liveFinChatWorkflowsCount: 0,
        liveFinEmailWorkflowsCount: 0,
        liveFinPhoneWorkflowsCount: 0,
        liveFinWorkflowsCount: 0,
        hasFinChatWorkflows: false,
        hasFinEmailWorkflows: false,
        hasFinPhoneWorkflows: false,
      },
    };
    this.setupRuleset = {
      emailSetupRuleset: null,
      finSetupRuleset: null,
      phoneSetupRuleset: null,
    };
    this.customAnswerCounts = {
      all: 0,
      finUsable: 0,
    };
  }

  get app() {
    return this.appService.app;
  }

  get validateEmailSetup(): ValidationResponse {
    if (this.detectIntercomradesDomainService.isIntercomradesDomain) {
      return { isValid: true };
    }

    // Emails are sent through the third party service in Fin Standalone apps.
    // E.g. via Zendesk of Salesforce
    if (this.app.canUseStandalone) {
      return { isValid: true };
    }

    if (this.app.canUseFeature('team-channels-email-for-fin')) {
      return { isValid: true };
    }

    if (!this.app.use_admin_app_email) {
      return {
        isValid: false,
        type: 'no_custom_sender',
        message: this.intl.t(
          'operator.workflows.visual-builder.validations.fin.email.no-custom-sender',
        ),
      };
    }

    if (!this.app.hasAdminReplyDefaultAddress) {
      return { isValid: false, type: 'no_custom_sender', message: this.defaultReplyInvalidMessage };
    }

    if (!this.validateEmailDefaultAddress.isValid) {
      return this.validateEmailDefaultAddress;
    }

    if (!this.validateDkimSettings.isValid) {
      return this.validateDkimSettings;
    }

    return { isValid: true };
  }

  get defaultReplyAddress() {
    return this.app.customEmailAddresses.find(
      (item: CustomEmailAddress) => item.id === this.app.admin_reply_default_address_id,
    );
  }

  get validateEmailDefaultAddress(): ValidationResponse {
    // If Promise is pending, we are still fetching the customEmailAddresses
    if (!this.app.customEmailAddresses.isPending) {
      if (!this.defaultReplyAddress || !this.defaultReplyAddress?.verified) {
        return {
          isValid: false,
          type: 'default_reply_invalid',
          message: this.defaultReplyInvalidMessage,
        };
      }
    }
    return { isValid: true };
  }

  get validateDkimSettings(): ValidationResponse {
    // If Promise is pending, we are still fetching the dkim_settings
    if (this.defaultReplyAddress && !this.app.dkim_settings.isPending) {
      let dkimSettings = this.app.dkim_settings.find((setting: DkimSettings) =>
        this.defaultReplyAddress?.email.toLowerCase().endsWith(`@${setting.domain}`),
      );
      if (!dkimSettings || !dkimSettings.validRecordExists || !dkimSettings.validDmarcExists) {
        return {
          isValid: false,
          type: 'default_reply_invalid',
          message: this.defaultReplyInvalidMessage,
        };
      }
    }
    return { isValid: true };
  }

  async load() {
    // if there are a lot of workflows, it takes a while to load the data
    // so we don't want to call the api too often in a short period of time
    let now = Date.now();
    if (this.isLoading || now - this.lastFetchTime < this.fetchInterval) {
      return this.loadFinPromise;
    }

    this.lastFetchTime = now;
    this.isLoading = true;
    let workflowsCountPromise = this.loadFinWorkflowsCount();
    let setupRulesetPromise = this.loadSetupRulesets();
    let liveResolutionBotBehaviorsPromise = this.loadLiveResolutionBotBehaviors();
    let customAnswerCountsPromise = this.loadCustomAnswerCounts();
    let knowledgeUsageSummaryPromise = this.loadKnowledgeUsageSummary();

    this.loadFinPromise = Promise.all([
      workflowsCountPromise,
      setupRulesetPromise,
      liveResolutionBotBehaviorsPromise,
      customAnswerCountsPromise,
      knowledgeUsageSummaryPromise,
    ]).finally(() => {
      this.isLoading = false;
    });
    return this.loadFinPromise;
  }

  async loadCustomAnswerCounts() {
    let response = await get('/ember/custom_answers/counts', { app_id: this.appService.app.id });

    this.customAnswerCounts = {
      all: response.all || 0,
      finUsable: response.live || 0,
    };
  }

  async loadKnowledgeUsageSummary() {
    let knowledgeUsageSummary = await this.knowledgeHubService.fetchKnowledgeUsageSummary();

    let finStats = structuredClone(this.finStats);
    finStats.finUsableArticlesCount = knowledgeUsageSummary[EntityType.ArticleContent]?.agent || 0;
    finStats.finUsableExternalContentCount =
      knowledgeUsageSummary[EntityType.ExternalContent]?.agent || 0;
    finStats.finUsableSnippetsCount = knowledgeUsageSummary[EntityType.ContentSnippet]?.agent || 0;
    finStats.finUsableActionsCount =
      knowledgeUsageSummary[EntityType.WorkflowConnectorAction]?.agent || 0;
    finStats.finUsableFilesCount = knowledgeUsageSummary[EntityType.FileSourceContent]?.agent || 0;
    this.finStats = finStats;
  }

  get allContentCount() {
    return (
      this.finStats.finUsableArticlesCount +
      this.finStats.finUsableExternalContentCount +
      this.finStats.finUsableSnippetsCount +
      this.finStats.finUsableFilesCount
    );
  }

  async loadFinWorkflowsCount() {
    let response: {
      live_chat_count: number;
      live_email_count: number;
      live_phone_count: number;
      has_chat_workflows: boolean;
      has_email_workflows: boolean;
      has_phone_workflows: boolean;
    } = await get('/ember/operator_workflows/get_live_fin_workflows_count', {
      app_id: this.app.id,
    });

    let finStats = structuredClone(this.finStats);
    finStats.workflowsCount.liveFinChatWorkflowsCount = response.live_chat_count ?? 0;
    finStats.workflowsCount.liveFinEmailWorkflowsCount = response.live_email_count ?? 0;
    finStats.workflowsCount.liveFinPhoneWorkflowsCount = response.live_phone_count ?? 0;
    finStats.workflowsCount.liveFinWorkflowsCount =
      (response.live_chat_count ?? 0) +
      (response.live_email_count ?? 0) +
      (response.live_phone_count ?? 0);
    finStats.workflowsCount.hasFinChatWorkflows = response.has_chat_workflows;
    finStats.workflowsCount.hasFinEmailWorkflows = response.has_email_workflows;
    finStats.workflowsCount.hasFinPhoneWorkflows = response.has_phone_workflows;
    this.finStats = finStats;
  }

  async loadSetupRulesets() {
    return Promise.all([
      this.loadSetupRuleset('email'),
      this.loadSetupRuleset('chat'),
      this.loadSetupRuleset('phone'),
    ]);
  }

  async loadSetupRuleset(setupType: ChannelType) {
    let rulesetId: string;

    switch (setupType) {
      case 'email':
        rulesetId = this.setupRuleset.emailSetupRuleset?.get('id');
        break;
      case 'phone':
        rulesetId = this.setupRuleset.phoneSetupRuleset?.get('id');
        break;
      case 'chat':
        rulesetId = this.setupRuleset.finSetupRuleset?.get('id');
        break;
    }

    let ruleset = rulesetId && this.store.peekRecord('matching-system/ruleset', rulesetId);
    if (!ruleset) {
      let response = await this.fetchSetupRuleset(setupType);
      if (response) {
        this.store.pushPayload({ 'matching-system/ruleset': response });
        ruleset = this.store.peekRecord('matching-system/ruleset', response.id);
      }
    } else {
      await ruleset.reload();
    }

    switch (setupType) {
      case 'email':
        this.setupRuleset = { ...this.setupRuleset, emailSetupRuleset: ruleset };
        break;
      case 'phone':
        this.setupRuleset = { ...this.setupRuleset, phoneSetupRuleset: ruleset };
        break;
      case 'chat':
        this.setupRuleset = { ...this.setupRuleset, finSetupRuleset: ruleset };
        break;
    }
  }

  async fetchSetupRuleset(setupType: ChannelType) {
    let endpoint = FIN_SETUP_BEHAVIOR_ENDPOINT;
    return get(endpoint, {
      app_id: this.appService.app.id,
      setup_type: setupType,
    });
  }

  async loadLiveResolutionBotBehaviors() {
    this.liveResolutionBotBehaviors = await this.outboundHomeService.contentSearch({
      object_types: [objectTypes.resolutionBotBehavior],
      states: [states.live],
      app_id: this.appService.app.id,
    });

    let liveFinProfilesCount = 0;

    this.liveResolutionBotBehaviors.contentWrappers?.forEach((contentWrapper: ContentWrapper) => {
      let content = contentWrapper.contents.firstObject;
      if (content?.contentData?.use_ai_answers || content?.contentData?.use_custom_answers) {
        liveFinProfilesCount += 1;
      }
    });

    let finStats = structuredClone(this.finStats);
    finStats.liveFinProfilesCount = liveFinProfilesCount;
    this.finStats = finStats;
  }

  get isFinLive() {
    return (
      this.isFinSetupEmailRulesetLive ||
      this.isFinSetupChatRulesetLive ||
      this.isFinSetupPhoneRulesetLive ||
      this.finStats.workflowsCount.liveFinChatWorkflowsCount > 0
    );
  }

  get totalFinLiveChatCount(): number {
    return (
      this.finStats.workflowsCount.liveFinChatWorkflowsCount + this.finStats.liveFinProfilesCount
    );
  }

  get totalContentCount(): number {
    return (
      (this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.agent || 0) +
      (this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.agent || 0) +
      (this.knowledgeHubService.usageSummary?.[EntityType.Answer]?.agent || 0) +
      (this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.agent || 0) +
      (this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.agent || 0)
    );
  }

  get hasContentReadyForFin(): boolean {
    return (
      !!this.knowledgeHubService.usageSummary &&
      (this.knowledgeHubService.usageSummary[EntityType.ArticleContent]?.agent > 0 ||
        this.knowledgeHubService.usageSummary[EntityType.Answer]?.agent > 0 ||
        this.knowledgeHubService.usageSummary[EntityType.ExternalContent]?.agent > 0 ||
        this.knowledgeHubService.usageSummary[EntityType.ContentSnippet]?.agent > 0 ||
        this.knowledgeHubService.usageSummary[EntityType.WorkflowConnectorAction]?.agent > 0 ||
        this.knowledgeHubService.usageSummary[EntityType.FileSourceContent]?.agent > 0)
    );
  }

  get isFinSetupChatRulesetLive(): boolean {
    return this.setupRuleset.finSetupRuleset?.get('state') === states.live;
  }

  get isFinSetupEmailRulesetLive(): boolean {
    return this.setupRuleset.emailSetupRuleset?.get('state') === states.live;
  }

  get isFinSetupPhoneRulesetLive(): boolean {
    return this.setupRuleset.phoneSetupRuleset?.get('state') === states.live;
  }

  get finSetupRulesetStateIsLive(): boolean {
    return (
      this.isFinSetupChatRulesetLive ||
      this.isFinSetupEmailRulesetLive ||
      this.isFinSetupPhoneRulesetLive
    );
  }

  isFinLiveInChannelOutsideSetup(channelType: ChannelType): boolean {
    switch (channelType) {
      case 'email':
        return this.finStats.workflowsCount.liveFinEmailWorkflowsCount > 0;
      case 'phone':
        return this.finStats.workflowsCount.liveFinPhoneWorkflowsCount > 0;
      case 'chat':
        return this.totalFinLiveChatCount > 0;
    }
  }
}
